<template>
<div id="personal">
  <div class="positionWrap">
    <div class="filter-box">
      <div class="search-box">
        <el-input v-model="conditionForm.searchValue" class="searchCt" placeholder="搜索职位、公司"></el-input>
        <div class="search-icon" @click="getPositionList">
          搜索
        </div>
      </div>
      <div class="address">
        <span>工作地点：</span>
        <label v-for="(item,index) in areaList" :key="index" @click="toggleArea(item.areaCode,index)" :class="{activeSub:areaSub==index}">
          {{item.areaName}}
        </label>
      </div>
      <div class="filter-ct">
        <el-select @change="getPositionList" clearable v-model="conditionForm.workExperience" placeholder="工作经验">
          <el-option v-for="item in workList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
        <el-select @change="getPositionList" clearable v-model="conditionForm.minimumEducation" placeholder="学历要求">
          <el-option v-for="item in backgroundList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
        <el-select @change="getPositionList" clearable v-model="conditionForm.enterpriseNature" placeholder="企业性质">
          <el-option v-for="item in natureList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
        <el-select @change="getPositionList" clearable v-model="conditionForm.scale" placeholder="企业规模">
          <el-option v-for="item in scaleList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
        <el-dropdown  trigger="click"  @command="handleCommand">
          <span class="drop-text">
            {{expectedPositionName}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="subdivision-menu">
              <el-dropdown-item :class="{activePosition:item.code==conditionForm.position}"  v-for="(item,index) in subList" :key="index" :command="item">{{item.name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="reset-btn" @click="reset">重置</div>
      </div>
    </div>
     <div class="position-box" v-show="jobList.length>0" v-loading="loadPosition">
        <div class="position-item" @click="jumpPosition(item)" v-for="(item,index) in jobList" :key="index">
          <div class="ps-left">
            <h4>
              {{item.positionName}}
               <em>
                {{item.minimumWage}}<strong v-show="item.maximumSalary">-{{item.maximumSalary}}</strong>
              </em>
            </h4>
            <p>
              <label v-show="item.areaName"><img style="width:14px" src="@/assets/imgs/location-icon.png">{{item.areaName}}</label>
              <label  v-show="item.minimumEducationName"><img src="@/assets/imgs/edu-icon.png">{{item.minimumEducationName}}</label>
              <label v-show="item.workExperienceName"><img src="@/assets/imgs/time-icon.png">{{item.workExperienceName}}</label>
            </p>
          </div>
          <div class="ps-mid">
            <img :src="item.enterpriseLogo" />
            <p>
              <span>{{item.enterpriseName}}</span>
              <label>{{item.enterpriseNatureName}} <label v-show="item.enterpriseNatureName && item.companyIndustryName">|</label> {{item.companyIndustryName}} <label v-show="item.companyIndustryName && item.scaleName">|</label> {{item.scaleName}}</label>
            </p>
          </div>
          <div class="chat-btn" @click.stop="jumpChat(item)" v-show="userInfo.phone">立即沟通</div>
          <div class="ps-bottom">
            <div class="hr">
              <img :src="item.headSculpture">
              <span>{{item.publisherName}} <em v-show="item.position">· {{item.position}}</em> </span>
              <span><em v-show="item.updateTime">{{item.updateTime}}</em> </span>
            </div>
            <div class="welfare">
              <span v-show="key<10" v-for="(w,key) in item.benefitsMap" :key="key">{{w}}</span>
            </div>
          </div>
        </div>
     </div>
     <el-empty v-show="jobList.length==0" :image-size="200" description="暂时没有符合该搜索条件的职位"></el-empty>
     <div class="pagination">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="conditionForm.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="conditionForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</div>
</template>

<script>
import { getJobApplicantPositionList ,getJobSegmentation, getAreaList, getDictData } from "@/api/index.js";
export default {
  name: "personal",
  data() {
    return {
      jobList: [],
      userInfo:{},
      conditionForm: {
        position:'',
        updateTime:'',
        searchValue: '',
        region: '',
        workExperience: '',
        enterpriseNature:'',
        minimumEducation:'',
        scale:'',
         pageSize:10,
         pageNum:1,
      },
       areaSub: 0,
      areaList: [],
      workList: [],
      natureList: [],
      backgroundList: [],
      scaleList:[],
      subList:[],
      total:0,
      loadPosition:true,
      expectedPositionName:'细分方向'
    }
  },
  mounted() {
    this.conditionForm.searchValue = this.$route.query.searchValue
    this.conditionForm.position = this.$route.query.expectedPosition
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getPositionList()
    this.getAreaList()
     this.getJobSegmentation()
    this.getDictData('work_experience')
    this.getDictData('enterprise_nature')
    this.getDictData('background_type')
     this.getDictData('scale_enterprises')
  },
  methods: {
     jumpChat(item){
      console.log(item)
      this.$router.push({
        path: '/chat',
        query: {
          positionId:item.id,
          recvId:item.publisherId
        }
      })
    },
     handleCommand(command){
      this.conditionForm.position = command.code
      this.expectedPositionName = command.name
      this.getPositionList()
    },
    getJobSegmentation(){
      getJobSegmentation({
        positionCode: this.conditionForm.position
      }).then(res=>{
        this.subList= res.rows
        this.subList.forEach(s=>{
          if(s.code == this.conditionForm.position){
            this.expectedPositionName =s.name
          }
        })
      })
    },
     sizeChange(val){
      this.conditionForm.pageSize = val
      this.getPositionList()
    },
    currentChange(val){
      this.conditionForm.pageNum = val
      this.getPositionList()
    },
    getDictData(dictType) {
      getDictData({
        dictType: dictType
      }).then(res => {
        if (dictType == 'work_experience') {
          this.workList = res.rows
        }
        if (dictType == 'enterprise_nature') {
          this.natureList = res.rows
        }
        if (dictType == 'background_type') {
          this.backgroundList = res.rows
        }
        if (dictType == 'scale_enterprises') {
          this.scaleList = res.rows
        }
      })
    },
    reset(){
        this.conditionForm.workExperience = ''
        this.conditionForm.enterpriseNature = ''
        this.conditionForm.minimumEducation = ''
        this.conditionForm.scale = ''
        this.conditionForm.position = ''
        this.expectedPositionName  = '细分方向'
        this.getPositionList()
    },
    toggleArea(areaCode,index) {
      this.conditionForm.region = areaCode
      this.areaSub = index
      this.getPositionList()
    },
    getAreaList() {
      getAreaList().then(res => {
        this.areaList = res.rows
      })
    },
    jumpPosition(item) {
       if(this.userInfo.type==3){
        this.$router.push({
          path: '/login',
        })
      }else{
        this.$router.push({
          path: '/jobDetail',
          query: {
            positionId: item.id
          }
        })
      }
    },
    getPositionList() {
      this.loadPosition = true
      getJobApplicantPositionList(this.conditionForm).then(res => {
        this.jobList = res.rows
         this.total  = res.total
         this.loadPosition = false
        var benefitsList = JSON.parse(localStorage.getItem('benefitsList'))

        this.jobList.forEach(item => {
          if (item.materialBenefits) {
            var benefits = item.materialBenefits.split(',')
            item.benefitsMap = []
            benefits.forEach(g => {
              benefitsList.forEach(s => {
                if (g == s.dictValue) {
                  item.benefitsMap.push(s.dictLabel)
                }
              })
            })
          }
        })
      })
    },
  }
};
</script>

<style lang="scss" scoped>
#personal {
  width: 100%;
  min-height: 800px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
}
 .address{
  margin-bottom: 20px;
 }
.positionWrap {
  width: 1200px;
  margin: 30px auto;
}
.pagination{
  margin-top: 20px;
}
.filter-box {
  min-height: 60px;
  padding: 25px 20px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 13px;

  span {
    font-size: 16px;
    color: #222222;
    display: inline-block;
    margin-right: 5px;
  }

  label {
    font-size: 14px;
    display: inline-block;
    margin-right: 25px;
    cursor: pointer;

  }

  .activeSub {
    color: #0077FF;
  }
  .reset-btn{
    width: 100px;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
    border-radius:4px;
    text-align: center;
    font-size: 13px;
    color: #fff;
    background: #0077FF;
   display: inline-block;
   cursor: pointer;
  }
}
.position-box {
  .position-item {
    min-height: 116px;
    margin-top: 13px;
    padding: 18px 20px 25px;
    background: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
  }

  .ps-left {
    padding-bottom: 20px;
    float: left;
    min-width: 500px;
    min-height: 60px;

    h4 {
      font-size: 18px;
      font-weight: 500;
      color: #222222;

      span {
        display: inline-block;
        width: 41px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        border: 1px solid #FF2400;
        border-radius: 2px;
        font-size: 14px;
        color: #FF2400;
        margin-left: 10px;
      }

      label {
        display: inline-block;
        width: 41px;
        height: 21px;
        border: 1px solid #F99746;
        border-radius: 2px;
        line-height: 21px;
        text-align: center;
        font-size: 14px;
        color: #F99746;
        margin-left: 9px;
        margin-right: 15px;
      }
      strong{
        font-weight: 300;
      }
      em {
        font-style: normal;
        font-size: 18px;
        color: #FF2400;
        margin-left: 20px;
      }
    }

    p {
      margin-top: 13px;

      img {
        position: relative;
        top: 3px;
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }

      label {
        display: inline-block;
        font-size: 14px;
        color: #999999;
        margin-right: 50px;
      }
    }
  }

  .ps-mid {
    float: left;
    margin-right: 10px;

    img {
      width: 61px;
      height: 61px;
      float: left;
      border-radius: 6px;
      margin-top: 3px;
    }

    span {
      display: block;
      font-size: 18px;
      color: #222222;
      margin-bottom: 6px;
      margin-top: 3px;
    }

    p {
      float: left;
      margin-left:15px;
      font-size: 14px;
      color: #999999;
      margin-top: 6px;
      margin-bottom: 8px;
    }
  }
 .chat-btn {
    float: right;
    width: 92px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: #fff;
    background: #0077FF;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 15px;
  }
  .ps-bottom {
    clear: both;
    border-top: 1px solid #ddd;
    position: relative;
    top: -6px;

    .welfare {
      float: left;
      position: relative;
      top: 2px;

      span {
        display: inline-block;
        min-width: 50px;
        padding: 0 5px;
        margin-top: 18px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        background: #EBF1F8;
        color: #0077FF;
        font-size: 14px;
        border-radius: 2px;
        margin-right: 10px;
      }
    }

    .hr {
      float: left;
      min-width: 190px;
      img {
        width: 30px;
        height: 30px;
        position: relative;
        top: 15px;
        border-radius: 50%;
      }

      label {
        display: inline-block;
        width: 66px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        background: #E6F9EF;
        font-size: 14px;
        color: #07C160;
        border-radius: 2px;
        position: relative;
        top: 7px;
      }
      em{
        font-style: normal;
      }
      span {
        display: inline-block;
        margin-left: 11px;
        font-size: 16px;
        position: relative;
        top: 7px;
        color: #666666;
        margin-right: 10px;
      }
    }
  }
}
.activePosition{
  color: #0077FF;
  background: #f8f8f8;
}
</style>
<style lang="scss">
.filter-box{
  .search-box{
    margin-left: 0;
     .el-input__inner{
      width: 770px;
      border: 1px solid #0077FF;
     }
     .search-box .search-icon {
       border: 1px solid #0077FF;
     }
  }
}
</style>
